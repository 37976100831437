<template>
  <!-- Start Preloader -->
  <div class="cs-preloader cs-accent_color cs-white_bg">
    <div class="cs-preloader_bg cs-center cs-accent_8_bg_2">
      <div class="cs-preloader_in cs-accent_15_border">
        <!-- <img src="../assets/design-agency/logo.svg" alt="Logo"> -->

        <img
          src="https://brunswickdonuts.com/img/logo_mini.9b43837c.svg"
          alt="Logo"
        />

        <!-- https://brunswickdonuts.com/img/logo_mini.9b43837c.svg -->
      </div>
    </div>
  </div>
  <!-- End Preloader -->

  <!-- Start Header Section -->
  <header ref="home" class="cs-site_header cs-style1 cs-type3 cs-sticky-header">
    <div class="cs-main_header">
      <div class="container">
        <div class="cs-main_header_in">
          <div class="cs-main_header_left">
            <a class="cs-site_branding" href="index.html">
              <img
                v-if="client.image"
                :src="wp_cms + `storage/${client.image}`"
                alt="Logo"
              />
            </a>
          </div>
          <div class="cs-main_header_center">
            <div class="cs-nav">
              <ul class="cs-nav_list">
                <li
                  class="menu-item-has-children cs-mega-menu current-menu-item"
                >
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('home')"
                    class="cs-smoth_scroll"
                    >Home</a
                  >
                </li>
                <li
                  v-if="
                    client.service !== undefined && client.service.length > 0
                  "
                  class="menu-item-has-children"
                >
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('service')"
                    class="cs-smoth_scroll"
                    >Service</a
                  >
                </li>
                <li v-if="client.show_about === 1">
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('about')"
                    class="cs-smoth_scroll"
                    >About</a
                  >
                </li>
                <li v-if="client.show_stores === 1">
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('location')"
                    class="cs-smoth_scroll"
                    >Locations</a
                  >
                </li>

                <li v-if="client.show_careers === 1">
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('careers')"
                    class="cs-smoth_scroll"
                    >Careers</a
                  >
                </li>

                <li>
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('team')"
                    class="cs-smoth_scroll"
                    >Team</a
                  >
                </li>

                <!-- <li v-if="client.show_contact === 1">
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('contact')"
                    class="cs-smoth_scroll"
                    >Contact</a
                  >
                </li> -->
                <li v-if="client.show_gallery === 1">
                  <a href="#" @click.prevent="scrollMeTo('gallery')">Events</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="cs-main_header_right">
            <div class="cs-toolbox">
              <a
                v-if="client != undefined"
                :href="client.url"
                target="_blank"
                class="cs-toolbox_btn cs-accent_bg_2 cs-primary_color cs-accent_bg_hover cs-white_hover cs-rounded text-uppercase"
                ><span>Hire Us</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- End Header Section -->

  <!-- Start Hero Slider -->
  <div class="cs-hero cs-style8 cs-center">
    <div class="container">
      <div
        class="cs-hero_text wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <!-- <div class="cs-hero_subtitle">UI/UX DESIGN AGENCY</div> -->
        <h1 v-if="client.slider !== undefined" class="cs-hero_title">
          {{ client.slider[0]["title"] }}
          <b class="cs-accent_color cs-with_bar">
            <div v-html="client.slider[0]['description']"></div>
            <svg
              width="296"
              height="13"
              viewBox="0 0 296 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cs-accent_color_2"
            >
              <path
                d="M2.2 5.1C78 5.5 153.8 5.1 229.6 4.1C251.1 3.8 272.6 3.4 294.2 3C296.1 3 296.1 0 294.2 0C218.4 1.4 142.5 2.2 66.7 2.2C45.2 2.2 23.7 2.2 2.1 2.1C0.199996 2.1 0.199996 5.1 2.2 5.1Z"
                fill="currentColor"
              />
              <path
                d="M22.8 11.8C106.4 11.8 189.9 12.1 273.5 12.9C275.4 12.9 275.4 9.89999 273.5 9.89999C189.9 9.09999 106.4 8.79999 22.8 8.79999C20.9 8.79999 20.9 11.8 22.8 11.8Z"
                fill="currentColor"
              />
            </svg>
          </b>
          <!-- <br/>human-centric <br/>
        <b class="cs-accent_color">products.</b> -->
        </h1>
        <div class="cs-hero_btns">
          <a
            v-if="client != undefined"
            :href="client.url"
            target="_blank"
            class="cs-btn cs-style6 cs-btn_lg cs-rounded text-uppercase cs-medium cs-accent_border cs-accent_bg cs-white cs-accent_10_bg_hover cs-accent_40_border_hover cs-smoth_scroll"
          >
            <span class="cs-btn_text">Now Hiring</span>
          </a>
          <a
            href="https://www.youtube.com/embed/jRcfE2xxSAw?autoplay=1"
            class="cs-player_btn cs-style1 cs-btn_md cs-center cs-video_open cs-accent_color_2"
          >
            <span
              class="cs-player_btn_in cs-center cs-accent_color cs-accent_bg_2"
              ><i class="fas fa-play"></i
            ></span>
            <span class="cs-video_animaiton cs-center">
              <span><span></span></span>
            </span>
          </a>
          <svg
            width="82"
            height="113"
            viewBox="0 0 82 113"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="cs-accent_color_2"
          >
            <path
              d="M54.9 3.09999C78.1 13.7 86.8 48.2 70.6 68.5C63.1 78 51.2 84.8 40.6 90.3C29.4 96.2 17.4 100.4 4.99998 103.2C3.09998 103.6 3.89998 106.5 5.79998 106.1C19.8 103 33.3 97.9 45.8 90.9C56.8 84.7 70 76.9 76.1 65.4C88.1 43.1 79.8 11.3 56.2 0.499987C54.6 -0.300013 53.1 2.29999 54.9 3.09999Z"
              fill="currentColor"
            />
            <path
              d="M4.89999 95.9C3.69999 99.1 2.19999 102.1 0.29999 105C-0.30001 105.8 0.29999 106.9 1.19999 107.2C5.89999 108.8 10.6 110.4 15.3 112.2C17.1 112.9 17.9 110 16.1 109.3C11.5 107.5 6.79999 105.8 1.99999 104.3C2.29999 105 2.59999 105.8 2.89999 106.5C4.99999 103.4 6.59999 100.2 7.79999 96.7C8.39999 94.9 5.49999 94.1 4.89999 95.9Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      class="cs-hero_blur_shape wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.4s"
    >
      <div class="">
        <img src="../assets/design-agency/hero-blur-shape.png" alt="" />
      </div>
    </div>
    <div class="cs-hero_img" v-if="client.slider !== undefined">
      <div>
        <img :src="wp_cms + `storage/${client.slider[0]['image']}`" />
      </div>
    </div>
  </div>
  <!-- End Hero Slider-->

  <!-- Start Service dynamic-->
  <!-- <div ref="service" class="cs-bg" data-src="../assets/design-agency/icon-box-bg.jpg" v-if="client.service !== undefined && client.service.length > 0">
    <div class="cs-height_140 cs-height_lg_80"></div>
    <div class="container wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
      <div class="cs-section_heading cs-style2 cs-size2">
        <div class="cs-section_subtitle">Our awesome services</div>
        <h2 v-if="client !== undefined" class="cs-section_title">{{ client.name }}<br/>
          will make your <b class="cs-accent_color cs-with_bar">
            business
            <svg width="208" height="11" viewBox="0 0 208 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="cs-accent_color_2">
              <path d="M8.90002 10.1C72.2 10.6 135.6 10.7 198.9 10.5C200.8 10.5 200.8 7.49998 198.9 7.49998C135.6 7.79998 72.2 7.69998 8.90002 7.09998C6.90002 7.09998 6.90002 10.1 8.90002 10.1Z" fill="currentColor"/>
              <path d="M1.90002 3.79999C69.9 3.79999 137.9 4.09999 205.9 4.89999C207.8 4.89999 207.8 1.89999 205.9 1.89999C137.9 1.19999 69.9 0.799988 1.90002 0.799988C-0.0999756 0.799988 -0.0999756 3.79999 1.90002 3.79999Z" fill="currentColor"/>
            </svg>              
          </b> easy</h2>
      </div>
      <div class="cs-height_70 cs-height_lg_50"></div>
    </div>
    <div class="container">
      <div class="row justify-content-center align-items-center cs-gap_60" v-if="client.service !== undefined">
        <div>
          <div class="col-lg-4 wow fadeIn justify-content-center align-items-center" data-wow-duration="1s" data-wow-delay="0.3s" v-for="value in client.service" v-bind:key="value.id">
            <div v-if="client.service !== undefined && value['status'] === 1">
              <a href="#" class="cs-icon_box cs-style6 cs-transition_3">
                <div class="cs-icon_box_in">
                  <img :src="wp_cms + `storage/${value['image']}`" alt="Service" />
                 
                  <h2 class="cs-icon_box_title cs-semi_bold cs-transition_3">{{ value["title"] }}</h2>
                  <div class="cs-icon_box_subtitle cs-transition_3" v-html="value['description']"></div>
                </div>
                <div class="cs-icon_box_shape cs-accent_color">
                  <svg class="cs-transition_3" xmlns="http://www.w3.org/2000/svg"  opacity="0.051" xmlns:xlink="http://www.w3.org/1999/xlink" width="247px" height="257px">
                    <path fill-rule="evenodd" fill="currentColor" d="M0.717,256.609 C0.717,256.609 7.824,164.313 102.262,127.457 C196.699,90.601 230.049,0.643 230.049,0.643 L246.891,241.481 L0.717,256.609 Z"/>
                  </svg>
                </div>
              </a>
            </div>
            <div class="cs-height_30 cs-height_lg_30"></div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="cs-height_110 cs-height_lg_50"></div>
  </div> -->
  <!-- End Service dynamic-->

  <!-- Start Service -->
  <div
    ref="service"
    class="cs-bg"
    data-src="../assets/design-agency/icon-box-bg.jpg"
    v-if="client.service !== undefined && client.service.length > 0"
  >
    <div class="cs-height_140 cs-height_lg_80"></div>
    <div
      class="container wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.2s"
    >
      <div class="cs-section_heading cs-style2 cs-size2">
        <div class="cs-section_subtitle">Our awesome services</div>
        <h2 v-if="client !== undefined" class="cs-section_title">
          {{ client.name }} <br />
          will make your
          <b class="cs-accent_color cs-with_bar">
            business
            <svg
              width="208"
              height="11"
              viewBox="0 0 208 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cs-accent_color_2"
            >
              <path
                d="M8.90002 10.1C72.2 10.6 135.6 10.7 198.9 10.5C200.8 10.5 200.8 7.49998 198.9 7.49998C135.6 7.79998 72.2 7.69998 8.90002 7.09998C6.90002 7.09998 6.90002 10.1 8.90002 10.1Z"
                fill="currentColor"
              />
              <path
                d="M1.90002 3.79999C69.9 3.79999 137.9 4.09999 205.9 4.89999C207.8 4.89999 207.8 1.89999 205.9 1.89999C137.9 1.19999 69.9 0.799988 1.90002 0.799988C-0.0999756 0.799988 -0.0999756 3.79999 1.90002 3.79999Z"
                fill="currentColor"
              />
            </svg>
          </b>
          easy
        </h2>
      </div>
      <div class="cs-height_70 cs-height_lg_50"></div>
    </div>
    <div class="container">
      <div
        class="row justify-content-center align-items-center"
        v-if="client.service !== undefined"
      >
        <div
          v-for="value in client.service"
          v-bind:key="value.id"
          class="col-lg-4 wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <div v-if="client.service !== undefined && value['status'] === 1">
            <div class="cs-icon_box cs-style6 cs-transition_3">
              <div class="cs-icon_box_in">
                <div
                  class="cs-icon_box_icon cs-center cs-transition_4"
                  data-src="../assets/design-agency/iconbox-shape1.svg"
                >
                  <img :src="wp_cms + `storage/${value['image']}`" alt="Logo" />
                </div>
                <h2 class="cs-icon_box_title cs-semi_bold cs-transition_3">
                  {{ value["title"] }}
                </h2>
                <div class="cs-icon_box_subtitle cs-transition_3">
                  <div
                    class="cs-icon_box_subtitle"
                    v-html="value['description']"
                  ></div>
                </div>
              </div>
              <div class="cs-icon_box_shape cs-accent_color">
                <svg
                  class="cs-transition_3"
                  xmlns="http://www.w3.org/2000/svg"
                  opacity="0.051"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="247px"
                  height="257px"
                >
                  <path
                    fill-rule="evenodd"
                    fill="currentColor"
                    d="M0.717,256.609 C0.717,256.609 7.824,164.313 102.262,127.457 C196.699,90.601 230.049,0.643 230.049,0.643 L246.891,241.481 L0.717,256.609 Z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="cs-height_30 cs-height_lg_30"></div>
        </div>
        <!-- .col -->
        <!-- <div class="col-lg-4 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
          <a href="service-details.html" class="cs-icon_box cs-style6 cs-transition_3">
            <div class="cs-icon_box_in">
              <div class="cs-icon_box_icon cs-center cs-transition_4" data-src="../assets/design-agency/iconbox-shape1.svg">
                <svg width="55" height="58" viewBox="0 0 55 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M44.1 46.6C44 46.6 43.8 46.5 43.7 46.5L41.6 44.4C40.6 43.4 39.2 42.9 37.8 43.1C36.1 43.3 34.6 42.3 34.1 40.7L33.8 39.8C33.7 39.3 33.8 38.7 34.2 38.3L36.8 35.7C37.2 35.3 37.9 35.1 38.5 35.4L39.1 35.6C41.6 36.7 43.4 38.9 43.8 41.6L44.5 46C44.5 46.2 44.4 46.4 44.2 46.5C44.3 46.6 44.2 46.6 44.1 46.6ZM38.4 42C39.8 42 41.3 42.6 42.3 43.6L43.3 44.6L42.8 41.7C42.4 39.3 40.9 37.4 38.7 36.4L38.1 36.2C37.9 36.1 37.7 36.2 37.5 36.3L35 39C34.9 39.1 34.8 39.3 34.9 39.5L35.2 40.4C35.5 41.5 36.6 42.3 37.8 42.1C37.9 42 38.2 42 38.4 42Z" fill="white"/>
                  <path d="M10.2001 46.7C10.1001 46.7 9.90005 46.6 9.80005 46.6C9.70005 46.5 9.60005 46.2 9.70005 46L13.9001 36.1C14.0001 35.9 14.1001 35.8 14.3001 35.8C14.5001 35.8 14.6001 35.8 14.7001 35.9L20.4001 41.6C20.5001 41.7 20.6001 41.9 20.5001 42C20.5001 42.2 20.4001 42.3 20.2001 42.4L10.3001 46.6C10.4001 46.6 10.3001 46.7 10.2001 46.7ZM14.6001 37.1L11.1001 45.2L19.2001 41.7L14.6001 37.1Z" fill="white"/>
                  <path d="M10.2 46.7C10.1 46.7 10.1 46.7 10 46.7C9.70004 46.6 9.60004 46.3 9.70004 46L11.6 41.5C11.7 41.3 11.8 41.2 12 41.2C12.2 41.2 12.3 41.2 12.4 41.3L15 43.9C15.2 44.1 15.2 44.4 15 44.6C14.8 44.8 14.5 44.8 14.3 44.6L12.3 42.6L10.7 46.4C10.6 46.5 10.4 46.7 10.2 46.7Z" fill="white"/>
                  <path d="M43.8001 18.2C44.8001 17.2 44.8001 15.6 43.8001 14.7L41.7001 12.6C40.7001 11.6 39.1001 11.6 38.2001 12.6L14.5001 36.3L20.2001 42L43.8001 18.2Z" fill="white" stroke="#350C0C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M20.1 42.4C20 42.4 19.8 42.3 19.7 42.3L14 36.6C13.8 36.4 13.8 36.1 14 35.9L37.7 12.2C38.9 11 40.8 11 41.9 12.2L44 14.3C45.2 15.5 45.2 17.4 44 18.5L20.5 42.3C20.4 42.4 20.3 42.4 20.1 42.4ZM15.2 36.3L20.1 41.3L43.4 18C43.8 17.6 44 17.1 44 16.6C44 16.1 43.8 15.6 43.4 15.2L41.3 13.1C40.5 12.3 39.3 12.3 38.5 13.1L15.2 36.3Z" fill="white"/>
                  <path d="M37.775 24.2265L42.0176 19.9839L36.3608 14.3271L32.1182 18.5697L37.775 24.2265Z" fill="white"/>
                  <path d="M37.8 24.7C37.7 24.7 37.5 24.7 37.4 24.6L31.7 18.9C31.6 18.8 31.6 18.7 31.6 18.5C31.6 18.3 31.7 18.2 31.7 18.1L36 14C36.2 13.8 36.5 13.8 36.7 14L42.4 19.7C42.5 19.8 42.5 19.9 42.5 20.1C42.5 20.3 42.4 20.4 42.4 20.5L38.2 24.7C38.1 24.7 37.9 24.7 37.8 24.7ZM32.8 18.6L37.8 23.5L41.3 20L36.3 15.1L32.8 18.6Z" fill="white"/>
                  <path d="M26.1 21.8L16 12.6C15 11.7 13.5 11.7 12.5 12.7L11.4 13.8C10.5 14.7 10.4 16.3 11.3 17.2L20.6 27.3" fill="white"/>
                  <path d="M20.6 27.8C20.5 27.8 20.3 27.7 20.2 27.6L10.9 17.5C9.79999 16.3 9.89999 14.5 11 13.4L12.1 12.3C13.2 11.1 15.1 11.1 16.3 12.2L26.4 21.5C26.6 21.7 26.6 22 26.4 22.2C26.2 22.4 25.9 22.4 25.7 22.2L15.6 12.9C14.8 12.2 13.6 12.2 12.8 13L11.7 14.1C10.9 14.9 10.9 16.1 11.6 16.9L20.9 27C21.1 27.2 21.1 27.5 20.9 27.7C20.8 27.8 20.7 27.8 20.6 27.8Z" fill="white"/>
                  <path d="M30.0001 34.9L33.2001 37.2L36.0001 34.4L33.6001 31.2" fill="white"/>
                  <path d="M33.2 37.7C33.1 37.7 33 37.7 32.9 37.6L29.7 35.2C29.5 35 29.4 34.7 29.6 34.5C29.8 34.3 30.1 34.2 30.3 34.4L33.1 36.5L35.3 34.3L33.2 31.5C33 31.3 33.1 31 33.3 30.8C33.5 30.6 33.8 30.7 34 30.9L36.4 34.1C36.5 34.3 36.5 34.6 36.4 34.8L33.6 37.6C33.4 37.7 33.3 37.7 33.2 37.7Z" fill="white"/>
                  <path d="M5.90002 8H0.900024C0.600024 8 0.400024 7.8 0.400024 7.5V2.5C0.400024 2.2 0.600024 2 0.900024 2H5.90002C6.20002 2 6.40002 2.2 6.40002 2.5V7.5C6.40002 7.8 6.20002 8 5.90002 8ZM1.40002 7H5.40002V3H1.40002V7Z" fill="white"/>
                  <path d="M53.9 8H48.9C48.6 8 48.4 7.8 48.4 7.5V2.5C48.4 2.2 48.6 2 48.9 2H53.9C54.2 2 54.4 2.2 54.4 2.5V7.5C54.4 7.8 54.2 8 53.9 8ZM49.4 7H53.4V3H49.4V7Z" fill="white"/>
                  <path d="M5.90002 56H0.900024C0.600024 56 0.400024 55.8 0.400024 55.5V50.5C0.400024 50.2 0.600024 50 0.900024 50H5.90002C6.20002 50 6.40002 50.2 6.40002 50.5V55.5C6.40002 55.8 6.20002 56 5.90002 56ZM1.40002 55H5.40002V51H1.40002V55Z" fill="white"/>
                  <path d="M53.9 56H48.9C48.6 56 48.4 55.8 48.4 55.5V50.5C48.4 50.2 48.6 50 48.9 50H53.9C54.2 50 54.4 50.2 54.4 50.5V55.5C54.4 55.8 54.2 56 53.9 56ZM49.4 55H53.4V51H49.4V55Z" fill="white"/>
                  <path d="M48.9 53.5H5.90002C5.60002 53.5 5.40002 53.3 5.40002 53V51H3.40002C3.10002 51 2.90002 50.8 2.90002 50.5V7.5C2.90002 7.2 3.10002 7 3.40002 7H5.40002V5C5.40002 4.7 5.60002 4.5 5.90002 4.5H48.9C49.2 4.5 49.4 4.7 49.4 5V7H51.4C51.7 7 51.9 7.2 51.9 7.5V50.5C51.9 50.8 51.7 51 51.4 51H49.4V53C49.4 53.3 49.2 53.5 48.9 53.5ZM6.40002 52.5H48.4V50.5C48.4 50.2 48.6 50 48.9 50H50.9V8H48.9C48.6 8 48.4 7.8 48.4 7.5V5.5H6.40002V7.5C6.40002 7.8 6.20002 8 5.90002 8H3.90002V50H5.90002C6.20002 50 6.40002 50.2 6.40002 50.5V52.5V52.5Z" fill="white"/>
                  <path d="M17.4 57.5H15.4C15.1 57.5 14.9 57.3 14.9 57C14.9 56.7 15.1 56.5 15.4 56.5H17.4C17.7 56.5 17.9 56.7 17.9 57C17.9 57.3 17.7 57.5 17.4 57.5Z" fill="white"/>
                  <path d="M39.4 57.5H19.4C19.1 57.5 18.9 57.3 18.9 57C18.9 56.7 19.1 56.5 19.4 56.5H39.4C39.7 56.5 39.9 56.7 39.9 57C39.9 57.3 39.7 57.5 39.4 57.5Z" fill="white"/>
                  <path d="M39.4 1.5H37.4C37.1 1.5 36.9 1.3 36.9 1C36.9 0.7 37.1 0.5 37.4 0.5H39.4C39.7 0.5 39.9 0.7 39.9 1C39.9 1.3 39.7 1.5 39.4 1.5Z" fill="white"/>
                  <path d="M35.4 1.5H15.4C15.1 1.5 14.9 1.3 14.9 1C14.9 0.7 15.1 0.5 15.4 0.5H35.4C35.7 0.5 35.9 0.7 35.9 1C35.9 1.3 35.7 1.5 35.4 1.5Z" fill="white"/>
                </svg>                  
              </div>
              <h2 class="cs-icon_box_title cs-semi_bold cs-transition_3">Design</h2>
              <div class="cs-icon_box_subtitle cs-transition_3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut </div>
            </div>
            <div class="cs-icon_box_shape cs-accent_color">
              <svg class="cs-transition_3" xmlns="http://www.w3.org/2000/svg"  opacity="0.051" xmlns:xlink="http://www.w3.org/1999/xlink" width="247px" height="257px">
                <path fill-rule="evenodd" fill="currentColor" d="M0.717,256.609 C0.717,256.609 7.824,164.313 102.262,127.457 C196.699,90.601 230.049,0.643 230.049,0.643 L246.891,241.481 L0.717,256.609 Z"/>
              </svg>
            </div>
          </a>
          <div class="cs-height_30 cs-height_lg_30"></div>
        </div> -->
        <!-- .col -->
        <!-- <div class="col-lg-4 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
          <a href="service-details.html" class="cs-icon_box cs-style6 cs-transition_3">
            <div class="cs-icon_box_in">
              <div class="cs-icon_box_icon cs-center cs-transition_4" data-src="../assets/design-agency/iconbox-shape1.svg">
                <svg width="63" height="60" viewBox="0 0 63 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M36.5 26C35.9 26 35.3 25.5 35.3 24.8V17H31.3V45H52.5C53.1 45 53.5 44.6 53.5 44V26H36.5Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M59.6 55.5H3.59998C1.89998 55.5 0.599976 54.2 0.599976 52.5C0.599976 50.8 1.89998 49.5 3.59998 49.5H59.6C61.3 49.5 62.6 50.8 62.6 52.5C62.6 54.2 61.3 55.5 59.6 55.5ZM3.59998 50.5C2.49998 50.5 1.59998 51.4 1.59998 52.5C1.59998 53.6 2.49998 54.5 3.59998 54.5H59.6C60.7 54.5 61.6 53.6 61.6 52.5C61.6 51.4 60.7 50.5 59.6 50.5H3.59998Z" fill="white"/>
                  <path d="M34.1 53H29.1C28.3 53 27.6 52.3 27.6 51.5V50C27.6 49.7 27.8 49.5 28.1 49.5C28.4 49.5 28.6 49.7 28.6 50V51.5C28.6 51.8 28.8 52 29.1 52H34.1C34.4 52 34.6 51.8 34.6 51.5V50C34.6 49.7 34.8 49.5 35.1 49.5C35.4 49.5 35.6 49.7 35.6 50V51.5C35.6 52.3 35 53 34.1 53Z" fill="white"/>
                  <path d="M11.6 3.5H7.59998C7.39998 3.5 7.09998 3.3 7.09998 3C7.09998 2.7 7.29998 2.5 7.59998 2.5H11.6C11.9 2.5 12.1 2.7 12.1 3C12.1 3.3 11.9 3.5 11.6 3.5Z" fill="white"/>
                  <path d="M9.59998 5.5C9.39998 5.5 9.09998 5.3 9.09998 5V1C9.09998 0.7 9.29998 0.5 9.59998 0.5C9.89998 0.5 10.1 0.7 10.1 1V5C10.1 5.3 9.89998 5.5 9.59998 5.5Z" fill="white"/>
                  <path d="M4.59998 10H0.599976C0.299976 10 0.0999756 9.8 0.0999756 9.5C0.0999756 9.2 0.399976 9 0.599976 9H4.59998C4.89998 9 5.09998 9.2 5.09998 9.5C5.09998 9.8 4.89998 10 4.59998 10Z" fill="white"/>
                  <path d="M2.59998 12C2.29998 12 2.09998 11.8 2.09998 11.5V7.5C2.09998 7.2 2.29998 7 2.59998 7C2.89998 7 3.09998 7.2 3.09998 7.5V11.5C3.09998 11.8 2.89998 12 2.59998 12Z" fill="white"/>
                  <path d="M52.6 45.5H10.6C9.79998 45.5 9.09998 44.8 9.09998 44V18C9.09998 17.2 9.79998 16.5 10.6 16.5H35.4C35.7 16.5 35.9 16.7 35.9 17C35.9 17.3 35.7 17.5 35.4 17.5H10.6C10.3 17.5 10.1 17.7 10.1 18V44C10.1 44.3 10.3 44.5 10.6 44.5H52.6C52.9 44.5 53.1 44.3 53.1 44V26C53.1 25.7 53.3 25.5 53.6 25.5C53.9 25.5 54.1 25.7 54.1 26V44C54.1 44.8 53.5 45.5 52.6 45.5Z" fill="white"/>
                  <path d="M21.6 59.5H19.6C19.3 59.5 19.1 59.3 19.1 59C19.1 58.7 19.3 58.5 19.6 58.5H21.6C21.9 58.5 22.1 58.7 22.1 59C22.1 59.3 21.9 59.5 21.6 59.5Z" fill="white"/>
                  <path d="M43.6 59.5H23.6C23.3 59.5 23.1 59.3 23.1 59C23.1 58.7 23.3 58.5 23.6 58.5H43.6C43.9 58.5 44.1 58.7 44.1 59C44.1 59.3 43.9 59.5 43.6 59.5Z" fill="white"/>
                  <g opacity="0.3">
                  <path opacity="0.3" d="M27.6 29.5H12.6C12.3 29.5 12.1 29.3 12.1 29C12.1 28.7 12.3 28.5 12.6 28.5H27.6C27.9 28.5 28.1 28.7 28.1 29C28.1 29.3 27.9 29.5 27.6 29.5Z" fill="white"/>
                  </g>
                  <g opacity="0.6">
                  <path opacity="0.6" d="M27.6 32.5H15.1C14.8 32.5 14.6 32.3 14.6 32C14.6 31.7 14.8 31.5 15.1 31.5H27.6C27.9 31.5 28.1 31.7 28.1 32C28.1 32.3 27.9 32.5 27.6 32.5Z" fill="white"/>
                  </g>
                  <g opacity="0.3">
                  <path opacity="0.3" d="M27.6 22.5H12.6C12.3 22.5 12.1 22.3 12.1 22C12.1 21.7 12.3 21.5 12.6 21.5H27.6C27.9 21.5 28.1 21.7 28.1 22C28.1 22.3 27.9 22.5 27.6 22.5Z" fill="white"/>
                  </g>
                  <g opacity="0.6">
                  <path opacity="0.6" d="M27.6 25.5H15.1C14.8 25.5 14.6 25.3 14.6 25C14.6 24.7 14.8 24.5 15.1 24.5H27.6C27.9 24.5 28.1 24.7 28.1 25C28.1 25.3 27.9 25.5 27.6 25.5Z" fill="white"/>
                  </g>
                  <g opacity="0.6">
                  <path opacity="0.6" d="M27.6 39.5H15.1C14.8 39.5 14.6 39.3 14.6 39C14.6 38.7 14.8 38.5 15.1 38.5H27.6C27.9 38.5 28.1 38.7 28.1 39C28.1 39.3 27.9 39.5 27.6 39.5Z" fill="white"/>
                  </g>
                  <g opacity="0.3">
                  <path opacity="0.3" d="M27.6 36.5H12.6C12.3 36.5 12.1 36.3 12.1 36C12.1 35.7 12.3 35.5 12.6 35.5H27.6C27.9 35.5 28.1 35.7 28.1 36C28.1 36.3 27.9 36.5 27.6 36.5Z" fill="white"/>
                  </g>
                  <path d="M61.4 9H37.4V6C37.4 5.4 37.8 5 38.4 5H60.4C61 5 61.4 5.4 61.4 6V9Z" fill="white"/>
                  <path d="M61.4 9.5H37.4C37.1 9.5 36.9 9.3 36.9 9V6C36.9 5.2 37.6 4.5 38.4 4.5H60.4C61.2 4.5 61.9 5.2 61.9 6V9C61.9 9.3 61.7 9.5 61.4 9.5ZM37.9 8.5H60.9V6C60.9 5.7 60.7 5.5 60.4 5.5H38.4C38.1 5.5 37.9 5.7 37.9 6V8.5Z" fill="white"/>
                  <path d="M60.4 24.5H38.4C37.6 24.5 36.9 23.8 36.9 23V9C36.9 8.7 37.1 8.5 37.4 8.5H61.4C61.7 8.5 61.9 8.7 61.9 9V23C61.9 23.8 61.2 24.5 60.4 24.5ZM37.9 9.5V23C37.9 23.3 38.1 23.5 38.4 23.5H60.4C60.7 23.5 60.9 23.3 60.9 23V9.5H37.9Z" fill="white"/>
                  <path d="M56.6 48.5C56.3 48.5 56.1 48.3 56.1 48V26C56.1 25.7 56.3 25.5 56.6 25.5C56.9 25.5 57.1 25.7 57.1 26V48C57.1 48.3 56.9 48.5 56.6 48.5Z" fill="white"/>
                  <path d="M6.59998 48.5C6.29998 48.5 6.09998 48.3 6.09998 48V16.5C6.09998 14.8 7.39998 13.5 9.09998 13.5H35.4C35.7 13.5 35.9 13.7 35.9 14C35.9 14.3 35.7 14.5 35.4 14.5H9.09998C7.99998 14.5 7.09998 15.4 7.09998 16.5V48C7.09998 48.3 6.89998 48.5 6.59998 48.5Z" fill="white"/>
                  <path d="M31.4 45.5C31.1 45.5 30.9 45.3 30.9 45V17C30.9 16.7 31.1 16.5 31.4 16.5C31.7 16.5 31.9 16.7 31.9 17V45C31.9 45.3 31.7 45.5 31.4 45.5Z" fill="white"/>
                  <path d="M46.9 19.2C46.8 19.2 46.7 19.2 46.6 19.1L43.6 16.8C43.5 16.7 43.4 16.6 43.4 16.4C43.4 16.2 43.5 16.1 43.6 16L46.6 13.7C46.8 13.5 47.1 13.6 47.3 13.8C47.5 14 47.4 14.3 47.2 14.5L44.7 16.4L47.2 18.3C47.4 18.5 47.5 18.8 47.3 19C47.2 19.2 47 19.2 46.9 19.2Z" fill="white"/>
                  <path d="M51.9 19.2C51.7 19.2 51.6 19.1 51.5 19C51.3 18.8 51.4 18.5 51.6 18.3L54.1 16.4L51.6 14.5C51.4 14.3 51.3 14 51.5 13.8C51.7 13.6 52 13.5 52.2 13.7L55.2 16C55.3 16.1 55.4 16.2 55.4 16.4C55.4 16.6 55.3 16.7 55.2 16.8L52.2 19.1C52.1 19.2 52 19.2 51.9 19.2Z" fill="white"/>
                  <path d="M48.9 19H48.8C48.5 18.9 48.4 18.7 48.4 18.4L49.4 14.4C49.5 14.1 49.7 14 50 14C50.3 14.1 50.4 14.3 50.4 14.6L49.4 18.6C49.3 18.8 49.1 19 48.9 19Z" fill="white"/>
                  <path d="M42.4 30H34.4V40H42.4V30Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M42.4 40.5H34.4C34.1 40.5 33.9 40.3 33.9 40V30C33.9 29.7 34.1 29.5 34.4 29.5H42.4C42.7 29.5 42.9 29.7 42.9 30V40C42.9 40.3 42.7 40.5 42.4 40.5ZM34.9 39.5H41.9V30.5H34.9V39.5Z" fill="white"/>
                  <path d="M50.4 30H42.4V35H50.4V30Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M50.4 35.5H42.4C42.1 35.5 41.9 35.3 41.9 35V30C41.9 29.7 42.1 29.5 42.4 29.5H50.4C50.7 29.5 50.9 29.7 50.9 30V35C50.9 35.3 50.7 35.5 50.4 35.5ZM42.9 34.5H49.9V30.5H42.9V34.5Z" fill="white"/>
                  <path d="M50.4 35H42.4V40H50.4V35Z" fill="white" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M50.4 40.5H42.4C42.1 40.5 41.9 40.3 41.9 40V35C41.9 34.7 42.1 34.5 42.4 34.5H50.4C50.7 34.5 50.9 34.7 50.9 35V40C50.9 40.3 50.7 40.5 50.4 40.5ZM42.9 39.5H49.9V35.5H42.9V39.5Z" fill="white"/>
                </svg>                  
              </div>
              <h2 class="cs-icon_box_title cs-semi_bold cs-transition_3">Development</h2>
              <div class="cs-icon_box_subtitle cs-transition_3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut </div>
            </div>
            <div class="cs-icon_box_shape cs-accent_color">
              <svg class="cs-transition_3" xmlns="http://www.w3.org/2000/svg"  opacity="0.051" xmlns:xlink="http://www.w3.org/1999/xlink" width="247px" height="257px">
                <path fill-rule="evenodd" fill="currentColor" d="M0.717,256.609 C0.717,256.609 7.824,164.313 102.262,127.457 C196.699,90.601 230.049,0.643 230.049,0.643 L246.891,241.481 L0.717,256.609 Z"/>
              </svg>
            </div>
          </a>
          <div class="cs-height_30 cs-height_lg_30"></div>
        </div> -->
        <!-- .col -->
      </div>
    </div>
    <div class="cs-height_110 cs-height_lg_50"></div>
  </div>
  <!-- End Service -->

  <!-- Start About  -->
  <div ref="about" class="cs-height_140 cs-height_lg_80"></div>
  <div class="container" v-if="client !== undefined && client.show_about === 1">
    <div class="row">
      <div
        class="col-lg-6 wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div class="cs-section_heading cs-style2 cs-size2">
          <div class="cs-section_subtitle">About Us</div>
          <h2 class="cs-section_title">
            {{ client.about.title }} <br /><b
              class="cs-extra_bold cs-accent_color cs-with_bar"
            >
              Corporation
              <svg
                width="208"
                height="11"
                viewBox="0 0 208 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cs-accent_color_2"
              >
                <path
                  d="M8.90002 10.1C72.2 10.6 135.6 10.7 198.9 10.5C200.8 10.5 200.8 7.49998 198.9 7.49998C135.6 7.79998 72.2 7.69998 8.90002 7.09998C6.90002 7.09998 6.90002 10.1 8.90002 10.1Z"
                  fill="currentColor"
                />
                <path
                  d="M1.90002 3.79999C69.9 3.79999 137.9 4.09999 205.9 4.89999C207.8 4.89999 207.8 1.89999 205.9 1.89999C137.9 1.19999 69.9 0.799988 1.90002 0.799988C-0.0999756 0.799988 -0.0999756 3.79999 1.90002 3.79999Z"
                  fill="currentColor"
                />
              </svg>
            </b>
          </h2>
          <div class="cs-section_text" v-html="companyAboutDesc"></div>
          <div class="cs-height_30 cs-height_lg_30"></div>
          <!-- <a href="portfolio.html" class="cs-btn cs-style6 cs-btn_lg cs-rounded text-uppercase cs-medium cs-accent_border cs-accent_bg cs-white cs-accent_10_bg_hover cs-accent_40_border_hover">
            <span class="cs-btn_text">View All</span>
          </a> -->
        </div>
        <div class="cs-height_0 cs-height_lg_50"></div>
      </div>
      <!-- .col -->
      <div
        class="col-lg-6 wow fadeInLeft"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div class="cs-right_full_width">
          <!-- <img
              :src="wp_cms + `storage/${client.about.image}`"
              class="cs-about_img cs-style1"
            /> -->
          <div class="cs-style2 cs-gap-40">
            <div
              class="container"
              data-autoplay="0"
              data-loop="1"
              data-speed="600"
              data-center="0"
              data-fade-slide="0"
              data-slides-per-view="1"
              data-variable-width="1"
            >
              <div class="cs-slider_wrapper">
                <div class="cs-slide">
                  <a
                    href="portfolio-details.html"
                    class="cs-portfolio cs-style3"
                  >
                    <div class="cs-portfolio_img">
                      <!-- <img src="../assets/design-agency/portfolio1.jpg" alt=""> -->
                      <img
                        :src="wp_cms + `storage/${client.about.image}`"
                        class="cs-about_img cs-style1"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- .cs-slider_container -->
          </div>
          <!-- .cs-slider -->
        </div>
      </div>
      <!-- .col -->
    </div>
  </div>
  <div class="cs-height_140 cs-height_lg_80"></div>
  <!-- End About -->

  <!-- Start Locations -->
  <div class="cs-accent_4_bg" ref="location">
    <!-- Location -->

    <Location
      v-if="client.show_stores === 1"
      :stores="stores"
      :data_count="per_page"
    />

    <div class="cs-height_140 cs-height_lg_80"></div>
  </div>
  <!-- End Locations -->

  <!-- Start Positions  -->
  <div ref="careers">
    <Positions
      v-if="client.show_careers === 1"
      :Stores="stores"
      :Per_Page="per_page"
    />
  </div>
  <!-- End Positions  -->

  <!-- Start Team Member -->
  <div v-if="client.team !== undefined">
    <div
      ref="team"
      class="cs-bg"
      data-src="../assets/design-agency/team-bg.jpg"
    >
      <div class="cs-height_135 cs-height_lg_75"></div>
      <div class="container">
        <div
          class="cs-section_heading cs-style2 cs-size2 wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div class="cs-section_subtitle">Our team</div>
          <h2 class="cs-section_title cs-medium">
            Our professional <br /><b class="cs-accent_color cs-with_bar">
              team members
              <svg
                width="208"
                height="11"
                viewBox="0 0 208 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cs-accent_color_2"
              >
                <path
                  d="M8.90002 10.1C72.2 10.6 135.6 10.7 198.9 10.5C200.8 10.5 200.8 7.49998 198.9 7.49998C135.6 7.79998 72.2 7.69998 8.90002 7.09998C6.90002 7.09998 6.90002 10.1 8.90002 10.1Z"
                  fill="currentColor"
                />
                <path
                  d="M1.90002 3.79999C69.9 3.79999 137.9 4.09999 205.9 4.89999C207.8 4.89999 207.8 1.89999 205.9 1.89999C137.9 1.19999 69.9 0.799988 1.90002 0.799988C-0.0999756 0.799988 -0.0999756 3.79999 1.90002 3.79999Z"
                  fill="currentColor"
                />
              </svg>
            </b>
          </h2>
        </div>
        <div class="cs-height_90 cs-height_lg_50"></div>
      </div>
      <div class="container">
        <div class="cs-team_member_5_group">
          <!-- <div class="cs-team_member cs-style5 cs-transition_4 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
          <div class="cs-team_img text-center">
            <img src="../assets/design-agency/team1.png" alt="Member">
          </div>
          <div class="cs-team_right cs-vertical_middle">
            <div class="cs-vertical_middle_in">
              <div class="cs-team_info">
                <h2 class="cs-member_name cs-semi_bold">Jesica Jessy</h2>
                <div class="cs-member_designation">Web Developer</div>
                <div class="cs-social_btns cs-style1">
                  <a href="#" class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"><i class="fab fa-facebook-f"></i></a>
                  <a href="#" class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"><i class="fab fa-twitter"></i></a>
                  <a href="#" class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"><i class="fab fa-instagram"></i></a>
                  <a href="#" class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"><i class="fab fa-pinterest-p"></i></a>
                </div>
              </div>
              <div class="cs-height_40 cs-height_lg_30"></div>
              <div class="cs-progressbar_wrap">
                <div class="cs-progressbar cs-style1">
                  <div class="cs-progressbar_head">
                    <h3 class="cs-progressbar_text cs-secondary_color">PHP</h3>
                    <div class="cs-progressbar_percentage">85%</div>
                  </div>
                  <div class="cs-progress cs-white_bg" data-progress="80">
                    <div class="cs-progress_in cs-accent_bg"></div>
                  </div>
                </div>
                <div class="cs-height_15 cs-height_lg_15"></div>
                <div class="cs-progressbar cs-style1">
                  <div class="cs-progressbar_head">
                    <h3 class="cs-progressbar_text cs-secondary_color">Java Script</h3>
                    <div class="cs-progressbar_percentage">90%</div>
                  </div>
                  <div class="cs-progress cs-white_bg" data-progress="90">
                    <div class="cs-progress_in cs-accent_bg_2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
          <div
            v-for="value in client.team"
            v-bind:key="value.id"
            class="cs-team_member cs-style5 cs-transition_4 active wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            <div class="cs-team_img text-center">
              <img :src="wp_cms + `storage/${value['image']}`" alt="Member" />
            </div>
            <div class="cs-team_right cs-vertical_middle">
              <div class="cs-vertical_middle_in">
                <div
                  class="cs-team_info"
                  v-if="client.team !== undefined && value['status'] === 1"
                >
                  <h2 class="cs-member_name cs-semi_bold">
                    {{ value["title"] }}
                  </h2>
                  <div
                    class="cs-member_designation"
                    v-html="companyTeamDesc(value['description'])"
                  ></div>

                  <div class="cs-social_btns cs-style1">
                    <a
                      href="#"
                      class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"
                      ><i class="fab fa-facebook-f"></i
                    ></a>
                    <a
                      href="#"
                      class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"
                      ><i class="fab fa-twitter"></i
                    ></a>
                    <a
                      href="#"
                      class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"
                      ><i class="fab fa-instagram"></i
                    ></a>
                    <a
                      href="#"
                      class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"
                      ><i class="fab fa-pinterest-p"></i
                    ></a>
                  </div>
                </div>
                <!-- <div class="cs-height_40 cs-height_lg_30"></div>
              <div class="cs-progressbar_wrap">
                <div class="cs-progressbar cs-style1">
                  <div class="cs-progressbar_head">
                    <h3 class="cs-progressbar_text cs-secondary_color">PHP</h3>
                    <div class="cs-progressbar_percentage">85%</div>
                  </div>
                  <div class="cs-progress cs-white_bg" data-progress="80">
                    <div class="cs-progress_in cs-accent_bg"></div>
                  </div>
                </div>
                <div class="cs-height_15 cs-height_lg_15"></div>
                <div class="cs-progressbar cs-style1">
                  <div class="cs-progressbar_head">
                    <h3 class="cs-progressbar_text cs-secondary_color">Java Script</h3>
                    <div class="cs-progressbar_percentage">90%</div>
                  </div>
                  <div class="cs-progress cs-white_bg" data-progress="90">
                    <div class="cs-progress_in cs-accent_bg_2"></div>
                  </div>
                </div>
              </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="cs-team_member cs-style5 cs-transition_4 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
          <div class="cs-team_img text-center">
            <img src="../assets/design-agency/team3.png" alt="Member">
          </div>
          <div class="cs-team_right cs-vertical_middle">
            <div class="cs-vertical_middle_in">
              <div class="cs-team_info">
                <h2 class="cs-member_name cs-semi_bold">Jesica Jessy</h2>
                <div class="cs-member_designation">Web Developer</div>
                <div class="cs-social_btns cs-style1">
                  <a href="#" class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"><i class="fab fa-facebook-f"></i></a>
                  <a href="#" class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"><i class="fab fa-twitter"></i></a>
                  <a href="#" class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"><i class="fab fa-instagram"></i></a>
                  <a href="#" class="cs-accent_bg_hover cs-white_hover cs-white_bg cs-ternary_color"><i class="fab fa-pinterest-p"></i></a>
                </div>
              </div>
              <div class="cs-height_40 cs-height_lg_30"></div>
              <div class="cs-progressbar_wrap">
                <div class="cs-progressbar cs-style1">
                  <div class="cs-progressbar_head">
                    <h3 class="cs-progressbar_text cs-secondary_color">PHP</h3>
                    <div class="cs-progressbar_percentage">85%</div>
                  </div>
                  <div class="cs-progress cs-white_bg" data-progress="80">
                    <div class="cs-progress_in cs-accent_bg"></div>
                  </div>
                </div>
                <div class="cs-height_15 cs-height_lg_15"></div>
                <div class="cs-progressbar cs-style1">
                  <div class="cs-progressbar_head">
                    <h3 class="cs-progressbar_text cs-secondary_color">Java Script</h3>
                    <div class="cs-progressbar_percentage">90%</div>
                  </div>
                  <div class="cs-progress cs-white_bg" data-progress="90">
                    <div class="cs-progress_in cs-accent_bg_2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        </div>
      </div>
      <div class="cs-height_140 cs-height_lg_80"></div>
    </div>
  </div>

  <!-- End Team Member -->

  <!-- Start Testimonial -->
  <!-- <div ref="testimonial" class="cs-bg" data-src="../assets/design-agency/testimonial-bg.jpg">
    <div class="cs-height_135 cs-height_lg_75"></div>
    <div class="container wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
      <div class="cs-section_heading cs-style2 cs-size2 text-center">
        <div class="cs-section_subtitle">Clients feedback</div>
        <h2 class="cs-section_title cs-medium">What our <b class="cs-accent_color cs-with_bar">
          client says
          <svg width="208" height="11" viewBox="0 0 208 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="cs-accent_color_2">
            <path d="M8.90002 10.1C72.2 10.6 135.6 10.7 198.9 10.5C200.8 10.5 200.8 7.49998 198.9 7.49998C135.6 7.79998 72.2 7.69998 8.90002 7.09998C6.90002 7.09998 6.90002 10.1 8.90002 10.1Z" fill="currentColor"/>
            <path d="M1.90002 3.79999C69.9 3.79999 137.9 4.09999 205.9 4.89999C207.8 4.89999 207.8 1.89999 205.9 1.89999C137.9 1.19999 69.9 0.799988 1.90002 0.799988C-0.0999756 0.799988 -0.0999756 3.79999 1.90002 3.79999Z" fill="currentColor"/>
          </svg> 
        </b></h2>
      </div>
      <div class="cs-height_90 cs-height_lg_50"></div>
    </div>
    <div class="container wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="cs-slider cs-style1 cs-remove_overflow">
            <div class="cs-slider_container" data-autoplay="3500" data-loop="1" data-speed="600" data-center="0" data-fade-slide="0" data-slides-per-view="1">
              <div class="cs-slider_wrapper">
                <div class="cs-slide">
                  <div class="cs-testimonial cs-style6 cs-type1">
                    <div class="cs-testimonial_text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus</div>
                    <div class="cs-testimonial_info">
                      <div class="cs-testimonial_avatar"><img src="../assets/design-agency/avater1.jpg" alt="Avater"></div>
                      <div class="cs-testimonial_meta">
                        <h3 class="cs-testimonial_avatar_name cs-semi_bold">Adward Maya</h3>
                        <div class="cs-testimonial_avatar_designation">Web  Designer</div>
                      </div>
                      <div class="cs-testimonial_icon cs-center cs-accent_color"><i class="fas fa-quote-right"></i></div>
                    </div>
                  </div>
                </div>
                <div class="cs-slide">
                  <div class="cs-testimonial cs-style6 cs-type1">
                    <div class="cs-testimonial_text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus</div>
                    <div class="cs-testimonial_info">
                      <div class="cs-testimonial_avatar"><img src="../assets/design-agency/avater2.jpg" alt="Avater"></div>
                      <div class="cs-testimonial_meta">
                        <h3 class="cs-testimonial_avatar_name cs-semi_bold">Adward Maya</h3>
                        <div class="cs-testimonial_avatar_designation">Web  Designer</div>
                      </div>
                      <div class="cs-testimonial_icon cs-center cs-accent_color"><i class="fas fa-quote-right"></i></div>
                    </div>
                  </div>
                </div>
                <div class="cs-slide">
                  <div class="cs-testimonial cs-style6 cs-type1">
                    <div class="cs-testimonial_text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus</div>
                    <div class="cs-testimonial_info">
                      <div class="cs-testimonial_avatar"><img src="../assets/design-agency/avater3.jpg" alt="Avater"></div>
                      <div class="cs-testimonial_meta">
                        <h3 class="cs-testimonial_avatar_name cs-semi_bold">Adward Maya</h3>
                        <div class="cs-testimonial_avatar_designation">Web  Designer</div>
                      </div>
                      <div class="cs-testimonial_icon cs-center cs-accent_color"><i class="fas fa-quote-right"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cs-pagination cs-style1 cs-hidden"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-height_140 cs-height_lg_80"></div>
  </div> -->
  <!-- End Testimonial -->

  <!-- Start Contact -->
  <!-- <div ref="contact" v-if="client.show_contact === 1">
    <Contact />
  </div> -->
  <!-- End Contact -->

  <!-- Start CTA -->
  <!-- <div class="cs-height_30 cs-height_lg_0"></div>
  <div class="cs-cta cs-style5 cs-bg" data-src="../assets/design-agency/cta-bg.jpg">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
          <div class="cs-left_full_width text-center">
            <div class="cs-cta_img"><img src="../assets/design-agency/cta-img.png" alt=""></div>
          </div>
        </div>
        <div class="col-xl-5 offset-xl-1 col-lg-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
          <div class="cs-vertical_middle">
            <div class="cs-cta_info">
              <h2 class="cs-cta_title">Have a project? <b class="cs-accent_color cs-with_bar">
                Let’s talk
                <svg width="208" height="11" viewBox="0 0 208 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="cs-accent_color_2">
                  <path d="M8.90002 10.1C72.2 10.6 135.6 10.7 198.9 10.5C200.8 10.5 200.8 7.49998 198.9 7.49998C135.6 7.79998 72.2 7.69998 8.90002 7.09998C6.90002 7.09998 6.90002 10.1 8.90002 10.1Z" fill="currentColor"/>
                  <path d="M1.90002 3.79999C69.9 3.79999 137.9 4.09999 205.9 4.89999C207.8 4.89999 207.8 1.89999 205.9 1.89999C137.9 1.19999 69.9 0.799988 1.90002 0.799988C-0.0999756 0.799988 -0.0999756 3.79999 1.90002 3.79999Z" fill="currentColor"/>
                </svg> 
              </b></h2>
              <div class="cs-cta_subtitle">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </div>
              <div class="cs-cta_btns">
                <a href="contact.html" class="cs-btn cs-style6 cs-btn_lg cs-rounded text-uppercase cs-medium cs-accent_border cs-accent_bg cs-white cs-accent_10_bg_hover cs-accent_40_border_hover">
                  <span class="cs-btn_text">Let’s talk</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- End CTA -->

  <!-- Start Blog Post -->
  <!-- <div data-src="../assets/design-agency/blog-bg.jpg">
    <div class="cs-height_135 cs-height_lg_75"></div>
    <div class="container wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
      <div class="cs-section_heading cs-style2 cs-size2">
        <div class="cs-section_subtitle">Our latest post</div>
        <h2 class="cs-section_title cs-medium">Our latest <b class="cs-accent_color cs-with_bar">
          articles
          <svg width="208" height="11" viewBox="0 0 208 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="cs-accent_color_2">
            <path d="M8.90002 10.1C72.2 10.6 135.6 10.7 198.9 10.5C200.8 10.5 200.8 7.49998 198.9 7.49998C135.6 7.79998 72.2 7.69998 8.90002 7.09998C6.90002 7.09998 6.90002 10.1 8.90002 10.1Z" fill="currentColor"/>
            <path d="M1.90002 3.79999C69.9 3.79999 137.9 4.09999 205.9 4.89999C207.8 4.89999 207.8 1.89999 205.9 1.89999C137.9 1.19999 69.9 0.799988 1.90002 0.799988C-0.0999756 0.799988 -0.0999756 3.79999 1.90002 3.79999Z" fill="currentColor"/>
          </svg> 
        </b></h2>
      </div>
      <div class="cs-height_90 cs-height_lg_50"></div>
    </div>
    <div class="container">
      <div class="cs-post_8_9_group">
        <div class="cs-post cs-style8 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
          <a href="blog-details.html" class="cs-post_thumb cs-accent_color">
            <div class="cs-post_thumb_in cs-bg" data-src="../assets/design-agency/post1.jpg"></div>
            <span class="cs-link-icon cs-center cs-accent_90_bg"><i class="fas fa-link"></i></span>
          </a>
          <div class="cs-post_label">
            <a href="#" class="cs-avatar cs-rounded">
              <img src="../assets/design-agency/avatar1.png" alt="#" class="cs-avatar_img">
              <div class="cs-avatar_name">Jonathon Doe</div>
            </a>
          </div>
          <h2 class="cs-post_title"><a href="blog-details.html">UX Guide: How create simple and minimal dashboard design</a></h2>
        </div>
        <div class="cs-post cs-style9 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
          <a href="blog-details.html" class="cs-post_thumb cs-accent_color">
            <div class="cs-post_thumb_in cs-bg" data-src="../assets/design-agency/post2.jpg"></div>
            <span class="cs-link-icon cs-center cs-accent_90_bg"><i class="fas fa-link"></i></span>
          </a>
          <div class="cs-post_right">
            <div class="cs-post_label">
              <a href="#" class="cs-avatar">
                <div class="cs-avatar_name cs-accent_10_bg_2 cs-accent_40_border_2 cs-accent_10_bg_hover cs-accent_40_border_hover cs-rounded">Jonathon Doe</div>
              </a>
            </div>
            <h2 class="cs-post_title"><a href="blog-details.html">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</a></h2>
          </div>
        </div>
        <div class="cs-post cs-style9 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
          <a href="blog-details.html" class="cs-post_thumb cs-accent_color">
            <div class="cs-post_thumb_in cs-bg" data-src="../assets/design-agency/post3.jpg"></div>
            <span class="cs-link-icon cs-center cs-accent_90_bg"><i class="fas fa-link"></i></span>
          </a>
          <div class="cs-post_right">
            <div class="cs-post_label">
              <a href="#" class="cs-avatar">
                <div class="cs-avatar_name cs-accent_10_bg_2 cs-accent_40_border_2 cs-accent_10_bg_hover cs-accent_40_border_hover cs-rounded">Jonathon Doe</div>
              </a>
            </div>
            <h2 class="cs-post_title"><a href="blog-details.html">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</a></h2>
          </div>
        </div>
        <div class="cs-post cs-style9 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
          <a href="blog-details.html" class="cs-post_thumb cs-accent_color">
            <div class="cs-post_thumb_in cs-bg" data-src="../assets/design-agency/post4.jpg"></div>
            <span class="cs-link-icon cs-center cs-accent_90_bg"><i class="fas fa-link"></i></span>
          </a>
          <div class="cs-post_right">
            <div class="cs-post_label">
              <a href="#" class="cs-avatar">
                <div class="cs-avatar_name cs-accent_10_bg_2 cs-accent_40_border_2 cs-accent_10_bg_hover cs-accent_40_border_hover cs-rounded">Jonathon Doe</div>
              </a>
            </div>
            <h2 class="cs-post_title"><a href="blog-details.html">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</a></h2>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-height_140 cs-height_lg_80"></div>
  </div> -->
  <!-- End Blog Post -->

  <div ref="gallery">
    <div class="container">
      <div class="cs-height_175 cs-height_lg_80"></div>
      <div class="row" v-if="client.gallery !== undefined">
        <div
          class="cs-section_heading cs-style2 cs-size2 text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <h2 class="cs-section_title">
            <b class="cs-accent_color" v-if="client.gallery !== undefined"
              >Gallery</b
            >
          </h2>
        </div>
        <div class="cs-height_65 cs-height_lg_45"></div>
      </div>
    </div>
    <div class="container">
      <div class="row card border-0" v-if="client.gallery !== undefined">
        <div class="d-flex justify-content-between">
          <div
            class="col-lg mx-2"
            v-for="(element, index) in client.gallery"
            :key="index"
          >
            <!-- You may display the image title if needed -->
            <h4 class="cs-icon_box_title cs-semi_bold">
              {{ element["title"] }}
            </h4>
            <div v-if="Array.isArray(element['image'])">
              <!-- If image is already an array, loop through it -->
              <div v-for="(img, imgIndex) in element['image']" :key="imgIndex">
                <div
                  class="cs-icon_box cs-style3 cs-color1 text-left wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="cs-icon_box_icon cs-center">
                    <!-- Display the image using the img element -->
                    <img :src="wp_cms + 'storage/' + img" />
                    <div class="cs-con_box-shape cs-accent_bg"></div>
                  </div>
                  <!-- You may display the image title if needed -->
                  <!-- <h2 class="cs-icon_box_title cs-semi_bold">{{ element["title"] }}</h2> -->
                </div>
                <div class="cs-height_30 cs-height_lg_30"></div>
              </div>
            </div>
            <div v-else>
              <!-- If image is a string, parse it into an array -->
              <div
                v-for="(img, imgIndex) in JSON.parse(element['image'])"
                :key="imgIndex"
              >
                <div
                  class="cs-icon_box cs-style3 cs-color1 text-left wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="cs-icon_box_icon cs-center">
                    <!-- Display the image using the img element -->
                    <img :src="wp_cms + 'storage/' + img" />
                    <div class="cs-con_box-shape cs-accent_bg"></div>
                  </div>
                </div>
                <div class="cs-height_30 cs-height_lg_30"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-height_110 cs-height_lg_50"></div>
  </div>
  <div ref="gallery" v-if="client.show_gallery === 1">
    <Gallery v-if="client.show_gallery === 1" :albums="client?.gallery" />
  </div>
  <!-- Footer Section -->
  <footer
    class="cs-footer cs-style3 cs-bg wow fadeIn"
    style="background-color: #f7fbf8"
    data-wow-duration="1s"
    data-wow-delay="0.3s"
    data-src="../assets/design-agency/footer-bg.jpg"
  >
    <div class="cs-footer_bg"></div>
    <div class="container">
      <!-- <div class="cs-height_115 cs-height_lg_75"></div>
      <div class="row justify-content-center align-items-center mt-5">
        <div class="cs-footer_item cs-text_widgert">
            <div class="cs-text_widgert_content">
              <img v-if="client.image" :src="wp_cms + `storage/${client.image}`" alt="Logo" />
            </div>
          </div>
          <div class="cs-footer_item cs-address_widgert" v-if="client != undefined">
            <ul>
              <li><i class="far fa-envelope-open"></i>{{client.email}}</li>
              <li><i class="far fa-address-book"></i>{{client.phone}}</li>
            </ul>
          </div>
      </div> -->

      <!-- <div class="row"> -->
      <!-- <div class="col-lg-3 col-md-6">
          <div class="cs-footer_item cs-text_widgert">
            <div class="cs-text_widgert_content">
              <img v-if="client.image" :src="wp_cms + `storage/${client.image}`" alt="Logo" />
            </div>
          </div>
          <div class="cs-footer_item cs-address_widgert" v-if="client != undefined">
            <ul>
              <li><i class="far fa-envelope-open"></i>{{client.email}}</li>
              <li><i class="far fa-address-book"></i>{{client.phone}}</li>
            </ul>
          </div>
        </div> -->
      <!-- .col -->
      <!-- <div class="col-lg-3 col-md-6">
          <div class="cs-footer_item widget_nav_menu">
            <h2 class="cs-widget_title">Services</h2>
            <ul class="menu">
              <li><a href="#">Web Design</a></li>
              <li><a href="#">Print Design</a></li>
              <li><a href="#">Branding</a></li>
              <li><a href="#">User Research</a></li>
            </ul>
          </div>
        </div> -->
      <!-- .col -->
      <!-- <div class="col-lg-3 col-md-6">
          <div class="cs-footer_item widget_nav_menu">
            <h2 class="cs-widget_title">Company</h2>
            <ul class="menu">
              <li><a href="#">Privacy policy</a></li>
              <li><a href="#">Sitemap</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Terms</a></li>
            </ul>
          </div>
        </div> -->
      <!-- .col -->
      <!-- <div class="col-lg-3 col-md-6">
          <div class="cs-footer_item cs-address_widgert">
            <h2 class="cs-widget_title">Subscribe Us</h2>
            <div class="cs-footer_newsletter cs-style1">
              <form action="#" class="cs-footer_newsletter_form">
                <input type="email" class="cs-footer_newsletter_input" placeholder="Enter your email">
                <button class="cs-footer_newsletter_btn cs-accent_bg cs-white cs-accent_bg_2_hover"><i class="fas fa-paper-plane"></i></button>
              </form>
            </div>
          </div>
          <div class="cs-footer_item">
            <div class="cs-social_btns cs-style1">
              <a href="#"><i class="fab fa-facebook-f"></i></a>
              <a href="#"><i class="fab fa-twitter"></i></a>
              <a href="#"><i class="fab fa-instagram"></i></a>
              <a href="#"><i class="fab fa-pinterest-p"></i></a>
            </div>
          </div>
         
        </div> -->
      <!-- .col -->
      <div class="row justify-content-center align-items-center mt-5">
        <div v-if="client != undefined">
          <ul>
            <li>
              <img
                class="mt-3"
                v-if="client.image"
                :src="wp_cms + `storage/${client.image}`"
                alt="Logo"
                width="150"
                height="100"
              />
            </li>
            <li><i class="far fa-envelope-open"></i>{{ client.email }}</li>
            <li><i class="far fa-address-book"></i>{{ client.phone }}</li>
          </ul>
        </div>
        <br />
        © {{ currentYear }}. All rights reserved by {{ client.name }}.
      </div>
      <!-- </div> -->
      <div class="cs-height_90 cs-height_lg_50"></div>
    </div>
  </footer>
  <!-- Footer Section -->
</template>

<script>
import Positions from "../components/positions.vue";
import Location from "../components/location.vue";
import { marked } from "marked";
// import Contact from "../components/contact.vue";
import Gallery from "../components/gallery.vue";

export default {
  components: {
    Location,
    Positions,
    Gallery,
    // Contact,
  },
  props: {
    msg: String,
    client: {
      image: null, // Set to null if the image is not provided initially
    },
  },
  mounted() {
    // console.log(this.client);
    // this.axios
    //   .get(this.career_url + "recruit-stores?key=" + this.key)
    //   .then((response) => {
    //     // console.log(response);
    //     this.stores = response.data.data;
    //     this.filtered = this.stores;

    //     this.aboutDesc = this.client?.about?.description;
    //     this.benefitsDesc = this.client?.benefits_description;
    //     this.cultureDesc = this.client?.culture_description;
    //   });

    this.getCurrentLocation()
      .then(({ latitude, longitude }) => {
        // Check if latitude and longitude are defined
        if (latitude && longitude) {
          // this.isViewAllEnabled = true;
          this.fetchStoresWithLocation(latitude, longitude);
        } else {
          // this.isViewAllEnabled = false;
          this.fetchAllStores();
        }
      })
      .catch((error) => {
        console.error("Failed to get current location:", error);
        this.fetchAllStores();
      });

    this.albums = this.client?.gallery;
  },
  data() {
    return {
      wp_cms: process.env.VUE_APP_API_URL,
      career_url: process.env.VUE_APP_CAREER_API_URL,
      key: process.env.VUE_APP_CLIENT_KEY,
      client_name: process.env.VUE_APP_CLIENT,

      client_access_key: process.env.VUE_APP_FE_ACCESS_CLIENT_URL_AND_KEY,

      test: {},
      stores: [],
      search: "",
      filtered: [],
      albums: [],
      per_page: 5,
      mode: "history",

      currentYear: new Date().getFullYear(),

      benefitsDesc: "",
      cultureDesc: "",
      aboutDesc: "",
      isViewAllEnabled: false,
    };
  },

  computed: {
    companyBenefitsDesc() {
      return marked(this.benefitsDesc);
    },
    companyCultureDesc() {
      return marked(this.cultureDesc);
    },
    companyAboutDesc() {
      return marked(this.aboutDesc);
    },
  },

  methods: {
    handleSearch() {
      let key = this.search.toLowerCase();
      if (key.length == 0) {
        this.filtered = this.stores;
      }
      this.filtered = this.stores.filter((store) =>
        store.location.address.toLowerCase().includes(key)
      );
    },

    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    companyTeamDesc(params) {
      return marked(params);
    },

    requestLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
              resolve({ latitude, longitude });
            },
            (error) => {
              console.error(error);
              reject(error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
          reject(new Error("Geolocation is not supported by this browser."));
        }
      });
    },

    getCurrentLocation() {
      return this.requestLocation().catch(() => {
        // Return null coordinates if geolocation fails or is denied
        return { latitude: null, longitude: null };
      });
    },

    fetchStoresWithLocation(latitude, longitude) {
      // latitude = 40.5753817;
      // longitude = -74.3223703;
      this.axios
        .get(
          `${this.career_url}recruit-stores?lat=${latitude}&lng=${longitude}&radius=${this.defaultLocRadius}&key=${this.key}`
        )
        .then((response) => {
          // alert(response?.data?.msg);
          // console.log(response, "-----------------RESPONSE-2");
          this.stores = response.data.data;
          this.filtered = this.stores;

          this.aboutDesc = this.client?.about?.description;
          this.benefitsDesc = this.client?.benefits_description;
          this.cultureDesc = this.client?.culture_description;

          this.isViewAllEnabled = response.data.isLocationFiltered;
        });
    },

    fetchAllStores() {
      this.axios
        .get(`${this.career_url}recruit-stores?key=${this.key}`)
        .then((response) => {
          // alert(response?.data?.msg);
          // console.log(response, "------------------RESPONSE-1");
          this.stores = response.data.data;
          this.filtered = this.stores;

          this.aboutDesc = this.client?.about?.description;
          this.benefitsDesc = this.client?.benefits_description;
          this.cultureDesc = this.client?.culture_description;

          this.isViewAllEnabled = response.data.isLocationFiltered;
        });
    },
  },
};
</script>
