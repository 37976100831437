<template>
  <!-- Start Preloader -->
  <div class="cs-preloader cs-accent_color cs-white_bg">
    <div class="cs-preloader_bg cs-center cs-accent_4_bg">
      <div class="cs-preloader_in cs-accent_15_border">
        <img src="../assets/saas/logo_mini.svg" alt="Logo" />
      </div>
    </div>
  </div>
  <!-- End Preloader -->

  <!-- Start Header Section -->
  <header class="cs-site_header cs-style2 cs-sticky-header cs-primary_font">
    <div class="cs-main_header">
      <div class="container">
        <div class="cs-main_header_in">
          <div class="cs-main_header_left">
            <div class="cs-hamburger_wrap cs-white">
              <div class="cs-hamburger">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <span class="cs-hamburger_text">Menu</span>
            </div>
            <div class="cs-nav_wrap">
              <div class="cs-nav_overlay cs-accent_bg_2"></div>
              <div class="cs-nav">
                <ul class="cs-nav_list">
                  <li class="current-menu-item">
                    <a href="#" @click.prevent="scrollMeTo('home')">Home</a>
                  </li>
                  <li v-if="client.show_about === 1">
                    <a href="#" @click.prevent="scrollMeTo('about')">About</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="scrollMeTo('service')"
                      >Service</a
                    >
                  </li>
                  <li v-if="client.show_stores === 1">
                    <a href="#" @click.prevent="scrollMeTo('location')"
                      >Location</a
                    >
                  </li>
                  <li v-if="client.show_careers === 1">
                    <a href="#" @click.prevent="scrollMeTo('careers')"
                      >Careers</a
                    >
                  </li>
                  <li>
                    <a href="#" @click.prevent="scrollMeTo('team')">Team</a>
                  </li>
                  <li v-if="client.show_contact === 1">
                    <a href="#" @click.prevent="scrollMeTo('contact')"
                      >Contact</a
                    >
                  </li>
                  <li v-if="client.show_gallery === 1">
                    <a href="#" @click.prevent="scrollMeTo('gallery')"
                      >Events</a
                    >
                  </li>
                </ul>
              </div>
              <div class="cs-nav_toolbox text-center">
                <div class="cs-height_20 cs-height_lg_5"></div>
                <ul class="cs-mp0 cs-secondary_font" v-if="client != undefined">
                  <li>{{ client.contact_email }}</li>
                  <li>{{ client.phone }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="cs-main_header_center">
            <a class="cs-site_branding" href="index.html">
              <img :src="wp_cms + `storage/${client.image}`" alt="Logo" />
            </a>
          </div>
          <div class="cs-main_header_right">
            <div class="cs-toolbox cs-center">
              <a
                v-if="client != undefined"
                :href="client.url"
                target="_blank"
                class="cs-toolbox_btn cs-accent_bg cs-white_bg_hover cs-accent_color_hover cs-accent_color_2_hover cs-rounded"
              >
                <span>Now Hiring</span>
              </a>
              <a
                v-if="client != undefined && client.employee_portal_url"
                :href="client.employee_portal_url"
                target="_blank"
                class="cs-toolbox_btn cs-accent_bg cs-white_bg_hover cs-accent_color_hover cs-accent_color_2_hover cs-rounded"
              >
                <span class="cs-btn_text">Employee Portal</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- End Header Section -->

  <!-- Start Slider -->
  <div ref="home" class="cs-hero cs-style3 text-center">
    <div class="cs-hero_bg_frame">
      <div class="cs-hero_bg cs-accent_bg_2"></div>
    </div>
    <div class="container">
      <div class="cs-hero_text" v-if="client.slider !== undefined">
        <h1
          class="cs-hero_title cs-medium wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <b class="cs-bold cs-white">{{ client.slider[0]["title"] }}</b> <br />
          <div v-html="client.slider[0]['description']"></div>
        </h1>
        <div
          class="cs-main_image wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
          v-if="client.slider !== undefined"
        >
          <img :src="wp_cms + `storage/${client.slider[0]['image']}`" />
        </div>
      </div>
    </div>
    <div class="cs-shape cs-style2 cs-parallax">
      <div class="cs-to_up">
        <div class="cs-shape_in"><span></span></div>
      </div>
    </div>
    <div class="cs-shape cs-style3 cs-parallax">
      <div class="cs-to_up">
        <div class="cs-shape_in"><span></span></div>
      </div>
    </div>
    <div class="cs-shape cs-style4 cs-parallax">
      <div class="cs-to_left"><div class="cs-shape_in"></div></div>
    </div>
  </div>
  <!-- End Hero -->

  <!-- Start About -->
  <div
    ref="about"
    class="cs-gradient_bg_1"
    v-if="client !== undefined && client.show_about === 1"
  >
    <div class="cs-height_80 cs-height_lg_25"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="cs-left_full_width text-end wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <img :src="wp_cms + `storage/${client.about.image}`" alt="About" />
          </div>
        </div>
        <!-- .col -->
        <div
          class="col-lg-5 offset-lg-1 wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <div class="cs-vertical_middle">
            <div class="cs-vertical_middle_in">
              <div class="cs-text_box cs-style1 cs-size1">
                <h2 class="cs-text_box_title cs-medium">
                  <b class="cs-bold cs-accent_color_2">{{
                    client.about.title
                  }}</b
                  >.
                </h2>
                <div class="cs-height_30 cs-height_lg_15"></div>
                <div class="cs-text_box_text" v-html="companyAboutDesc"></div>
                <div class="cs-height_40 cs-height_lg_25"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- .col -->
      </div>
    </div>
    <div class="cs-height_70 cs-height_lg_80"></div>
  </div>
  <!-- End About -->

  <!-- Start Service -->
  <div ref="service" class="cs-height_135 cs-height_lg_80"></div>
  <div
    class="container wow fadeInUp"
    data-wow-duration="1s"
    data-wow-delay="0.2s"
  >
    <div class="cs-section_heading cs-style2 text-center cs-size1">
      <div class="cs-section_subtitle">What we do for you</div>
      <h2 class="cs-section_title cs-medium" v-if="client !== undefined">
        {{ client.name }} <b class="cs-bold cs-accent_color_2">featurs.</b>
      </h2>
    </div>
    <div class="cs-height_70 cs-height_lg_50"></div>
  </div>
  <div class="container">
    <div
      class="row justify-content-center align-items-center"
      v-if="client.service !== undefined"
    >
      <div
        class="col-lg wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
        v-for="value in client.service"
        v-bind:key="value.id"
      >
        <div
          class="cs-icon_box cs-style3 text-center"
          v-if="client.service !== undefined && value['status'] === 1"
        >
          <div class="cs-icon_box_icon cs-center">
            <img :src="wp_cms + `storage/${value['image']}`" alt="Service" />

            <div class="cs-con_box-shape cs-accent_bg"></div>
          </div>

          <h2 class="cs-icon_box_title cs-semi_bold">{{ value["title"] }}</h2>
          <div class="cs-icon_box_subtitle" v-html="value['description']"></div>
        </div>
        <div class="cs-height_30 cs-height_lg_30"></div>
      </div>
    </div>
  </div>

  <div class="cs-height_110 cs-height_lg_50"></div>
  <!-- End Service -->

  <!-- Start Locations -->
  <div class="cs-accent_4_bg" ref="location">
    <!-- Location -->

    <Location
      v-if="client.show_stores === 1"
      :stores="stores"
      :data_count="per_page"
    />

    <div class="cs-height_140 cs-height_lg_80"></div>
  </div>
  <!-- End Locations -->

  <!-- Start Positions  -->
  <div ref="careers">
    <Positions
      v-if="client.show_careers === 1"
      :Stores="stores"
      :Per_Page="per_page"
    />
  </div>
  <!-- End Positions  -->

  <!-- Start Team -->
  <div ref="team" class="cs-height_135 cs-height_lg_80"></div>

  <div
    class="container wow fadeInUp"
    data-wow-duration="1s"
    data-wow-delay="0.2s"
  >
    <div class="cs-section_heading cs-style2 text-center cs-size1">
      <div class="cs-section_subtitle" v-if="client !== undefined">
        Meet Our Team
      </div>
      <h2 class="cs-section_title cs-medium" v-if="client !== undefined">
        {{ client.name }}
        <b class="cs-bold cs-accent_color_2" v-if="client.team !== undefined"
          >Team Members</b
        >
      </h2>
    </div>
    <div class="cs-height_70 cs-height_lg_50"></div>
  </div>
  <div class="container justify-content-center">
    <div
      class="row justify-content-center align-items-center"
      v-if="client.team !== undefined"
    >
      <div
        class="col-lg wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
        v-for="value in client.team"
        v-bind:key="value.id"
      >
        <div v-if="client.team !== undefined && value['status'] === 1">
          <div class="cs-icon_box cs-style3 text-center">
            <div class="cs-icon_box_icon cs-center">
              <img :src="wp_cms + `storage/${value['image']}`" alt="Team" />

              <div class="cs-con_box-shape cs-accent_bg"></div>
            </div>

            <h2 class="cs-icon_box_title cs-semi_bold">{{ value["title"] }}</h2>
            <div
              class="cs-icon_box_subtitle"
              v-html="companyTeamDesc(value['description'])"
            ></div>
          </div>
          <div class="cs-height_30 cs-height_lg_30"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="cs-height_110 cs-height_lg_50"></div>

  <!-- End Team -->

  <!-- Start Benefits -->
  <div class="cs-gradient_bg_1" v-if="client !== undefined">
    <div class="cs-height_80 cs-height_lg_25"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="cs-left_full_width text-end wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <img
              :src="wp_cms + `storage/${client.benefit_image}`"
              alt="Benefit"
            />
          </div>
        </div>

        <!-- .col -->
        <div
          class="col-lg-5 offset-lg-1 wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <div class="cs-vertical_middle">
            <div class="cs-vertical_middle_in">
              <div class="cs-text_box cs-style1 cs-size1">
                <h2 class="cs-text_box_title cs-medium">
                  <b class="cs-bold cs-accent_color_2">{{
                    client.benefit_title
                  }}</b>
                  <b class="cs-bold cs-accent_color_2"></b>.
                </h2>
                <div class="cs-height_30 cs-height_lg_15"></div>
                <div
                  class="cs-text_box_text"
                  v-html="companyBenefitsDesc"
                ></div>
                <div class="cs-height_40 cs-height_lg_25"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- .col -->
      </div>
    </div>
    <div class="cs-height_70 cs-height_lg_80"></div>
  </div>
  <!-- End Benefits -->

  <!-- Start Culture -->
  <div class="cs-gradient_bg_1" v-if="client !== undefined">
    <div class="cs-height_80 cs-height_lg_25"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="cs-left_full_width text-end wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <img
              :src="wp_cms + `storage/${client.culture_image}`"
              alt="Culture"
            />
          </div>
        </div>

        <!-- .col -->
        <div
          class="col-lg-5 offset-lg-1 wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <div class="cs-vertical_middle">
            <div class="cs-vertical_middle_in">
              <div class="cs-text_box cs-style1 cs-size1">
                <h2 class="cs-text_box_title cs-medium">
                  <b class="cs-bold cs-accent_color_2">{{
                    client.culture_title
                  }}</b>
                  <b class="cs-bold cs-accent_color_2"></b>.
                </h2>
                <div class="cs-height_30 cs-height_lg_15"></div>
                <div class="cs-text_box_text" v-html="companyCultureDesc"></div>
                <div class="cs-height_40 cs-height_lg_25"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- .col -->
      </div>
    </div>
    <div class="cs-height_70 cs-height_lg_80"></div>
  </div>
  <!-- End Culture -->

  <!-- Start Contact -->
  <div ref="contact" v-if="client.show_contact === 1">
    <Contact />
  </div>
  <!-- End Contact -->

  <div ref="gallery">
    <div class="container">
      <div class="cs-height_175 cs-height_lg_80"></div>
      <div class="row" v-if="client.gallery !== undefined">
        <div
          class="cs-section_heading cs-style2 cs-size2 text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <h2 class="cs-section_title">
            <b class="cs-accent_color" v-if="client.gallery !== undefined"
              >Gallery</b
            >
          </h2>
        </div>
        <div class="cs-height_65 cs-height_lg_45"></div>
      </div>
    </div>
    <div class="container">
      <div class="row card border-0" v-if="client.gallery !== undefined">
        <div class="d-flex justify-content-between">
          <div
            class="col-lg mx-2"
            v-for="(element, index) in client.gallery"
            :key="index"
          >
            <!-- You may display the image title if needed -->
            <h4 class="cs-icon_box_title cs-semi_bold">
              {{ element["title"] }}
            </h4>
            <div v-if="Array.isArray(element['image'])">
              <!-- If image is already an array, loop through it -->
              <div v-for="(img, imgIndex) in element['image']" :key="imgIndex">
                <div
                  class="cs-icon_box cs-style3 cs-color1 text-left wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="cs-icon_box_icon cs-center">
                    <!-- Display the image using the img element -->
                    <img :src="wp_cms + 'storage/' + img" />
                    <div class="cs-con_box-shape cs-accent_bg"></div>
                  </div>
                  <!-- You may display the image title if needed -->
                  <!-- <h2 class="cs-icon_box_title cs-semi_bold">{{ element["title"] }}</h2> -->
                </div>
                <div class="cs-height_30 cs-height_lg_30"></div>
              </div>
            </div>
            <div v-else>
              <!-- If image is a string, parse it into an array -->
              <div
                v-for="(img, imgIndex) in JSON.parse(element['image'])"
                :key="imgIndex"
              >
                <div
                  class="cs-icon_box cs-style3 cs-color1 text-left wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="cs-icon_box_icon cs-center">
                    <!-- Display the image using the img element -->
                    <img :src="wp_cms + 'storage/' + img" />
                    <div class="cs-con_box-shape cs-accent_bg"></div>
                  </div>
                </div>
                <div class="cs-height_30 cs-height_lg_30"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-height_110 cs-height_lg_50"></div>
  </div>
  <!-- Start Gallery -->
  <div ref="gallery" v-if="client.show_gallery === 1">
    <Gallery v-if="client.show_gallery === 1" :albums="client?.gallery" />
  </div>
  <!-- End Gallery -->
  <!-- Footer Section -->
  <footer class="cs-footer cs-style1 cs-type1 rounded-0 cs-accent_4_bg">
    <div class="cs-copyright text-center cs-primary_color cs-accent_4_bg">
      <div class="container">
        Copyright © {{ currentYear }}. All rights reserved by {{ client.name }}.
      </div>
    </div>
  </footer>
  <!-- Footer Section -->
</template>

<script>
import Positions from "../components/positions.vue";
import Location from "../components/location.vue";
import { marked } from "marked";
import Contact from "../components/contact.vue";
import Gallery from "../components/gallery.vue";

export default {
  components: {
    Location,
    Positions,
    Contact,
    Gallery,
  },
  props: {
    msg: String,
    client: {},
  },
  mounted() {
    // console.log(this.client);
    this.axios
      .get(this.career_url + "recruit-stores?key=" + this.key)
      .then((response) => {
        // console.log(response);
        this.stores = response.data.data;
        this.filtered = this.stores;

        this.aboutDesc = this.client?.about?.description;
        this.benefitsDesc = this.client?.benefits_description;
        this.cultureDesc = this.client?.culture_description;
      });

    // this.getCurrentLocation()
    //   .then(({ latitude, longitude }) => {
    //     // Check if latitude and longitude are defined
    //     if (latitude && longitude) {
    //       // this.isViewAllEnabled = true;
    //       this.fetchStoresWithLocation(latitude, longitude);
    //     } else {
    //       // this.isViewAllEnabled = false;
    //       this.fetchAllStores();
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Failed to get current location:", error);
    //     this.fetchAllStores();
    //   });

    this.albums = this.client?.gallery;
  },
  data() {
    return {
      wp_cms: process.env.VUE_APP_API_URL,
      career_url: process.env.VUE_APP_CAREER_API_URL,
      key: process.env.VUE_APP_CLIENT_KEY,
      client_name: process.env.VUE_APP_CLIENT,

      client_access_key: process.env.VUE_APP_FE_ACCESS_CLIENT_URL_AND_KEY,

      test: {},
      stores: [],
      search: "",
      filtered: [],
      albums: [],
      per_page: 5,
      mode: "history",

      currentYear: new Date().getFullYear(),

      benefitsDesc: "",
      cultureDesc: "",
      aboutDesc: "",
      isViewAllEnabled: false,
    };
  },

  computed: {
    companyBenefitsDesc() {
      return marked(this.benefitsDesc);
    },
    companyCultureDesc() {
      return marked(this.cultureDesc);
    },
    companyAboutDesc() {
      return marked(this.aboutDesc);
    },
  },

  methods: {
    handleSearch() {
      let key = this.search.toLowerCase();
      if (key.length == 0) {
        this.filtered = this.stores;
      }
      this.filtered = this.stores.filter((store) =>
        store.location.address.toLowerCase().includes(key)
      );
    },

    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    companyTeamDesc(params) {
      return marked(params);
    },

    // requestLocation() {
    //   return new Promise((resolve, reject) => {
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //           const latitude = position.coords.latitude;
    //           const longitude = position.coords.longitude;
    //           console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    //           resolve({ latitude, longitude });
    //         },
    //         (error) => {
    //           console.error(error);
    //           reject(error);
    //         }
    //       );
    //     } else {
    //       console.error("Geolocation is not supported by this browser.");
    //       reject(new Error("Geolocation is not supported by this browser."));
    //     }
    //   });
    // },

    // getCurrentLocation() {
    //   return this.requestLocation().catch(() => {
    //     // Return null coordinates if geolocation fails or is denied
    //     return { latitude: null, longitude: null };
    //   });
    // },

    // fetchStoresWithLocation(latitude, longitude) {
    //   // latitude = 40.5753817;
    //   // longitude = -74.3223703;
    //   this.axios
    //     .get(
    //       `${this.career_url}recruit-stores?lat=${latitude}&lng=${longitude}&radius=${this.defaultLocRadius}&key=${this.key}`
    //     )
    //     .then((response) => {
    //       // alert(response?.data?.msg);
    //       // console.log(response, "-----------------RESPONSE-2");
    //       this.stores = response.data.data;
    //       this.filtered = this.stores;

    //       this.aboutDesc = this.client?.about?.description;
    //       this.benefitsDesc = this.client?.benefits_description;
    //       this.cultureDesc = this.client?.culture_description;

    //       this.isViewAllEnabled = response.data.isLocationFiltered;
    //     });
    // },

    // fetchAllStores() {
    //   this.axios
    //     .get(`${this.career_url}recruit-stores?key=${this.key}`)
    //     .then((response) => {
    //       // alert(response?.data?.msg);
    //       // console.log(response, "------------------RESPONSE-1");
    //       this.stores = response.data.data;
    //       this.filtered = this.stores;

    //       this.aboutDesc = this.client?.about?.description;
    //       this.benefitsDesc = this.client?.benefits_description;
    //       this.cultureDesc = this.client?.culture_description;

    //       this.isViewAllEnabled = response.data.isLocationFiltered;
    //     });
    // },
  },
};
</script>
